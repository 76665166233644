export default class pubSlider {

    ids = {
        slider: null,
        ctrl: {
            left: null,
            right: null,
            bullets: null
        }
    }

    nodes = {
        slider: null,
        tape: null,
        ctrl: {
            left: null,
            right: null,
            bullets: null
        }
    }

    param = {
        totalSlides: 0,
        tapeWidth: 0,
        startingSlide: 3,
        range: false,
        slideWidth: false,
        desktopRange: 5,
        mobileRange: 3,
        desktopSlide: 20, // percents
        mobileSlide: 33.3, // percents
    }

    state = {
        currentSlide: null
    }

    resizeObserver = false;

    constructor(sliderId, params) {
        this.ids.slider = sliderId;

        this.ids.ctrl.left = params.leftSlideId;
        this.ids.ctrl.right = params.rightSlideId;
        this.ids.ctrl.bullets = params.bulletsId;
        this.param.startingSlide = parseInt(params.startingSlide);

        this.init();
    }

    init() {
        this.nodes.slider = document.getElementById(this.ids.slider);
        if(!this.nodes.slider) {
            return;
        }
        this.nodes.tape = this.nodes.slider.querySelector('.tape');
        this.nodes.ctrl.left = document.getElementById(this.ids.ctrl.left);
        if(!this.nodes.ctrl.left) {
            console.log('Can\'t find left slide control');
        }
        this.nodes.ctrl.right = document.getElementById(this.ids.ctrl.right);
        if(!this.nodes.ctrl.right) {
            console.log('Can\'t find right slide control');
        }
        this.nodes.ctrl.bullets = document.getElementById(this.ids.ctrl.bullets);

        // get total slides
        this.param.totalSlides = this.nodes.slider.querySelectorAll('.slide').length;
        this.param.tapeWidth = this.nodes.slider.offsetWidth;

        this.calcDimensions();

        // bind events
        this.bindEvents();

        this.slideTo(this.param.startingSlide);

        this.watchSize();
    }

    watchSize() {
        this.resizeObserver = new ResizeObserver(() => { this.calcDimensions(); this.slideTo(this.state.currentSlide); });
        this.resizeObserver.observe(this.nodes.slider);
    }

    calcDimensions() {
        // check resolution
        if(window.innerWidth < 990) {
            // mobile
            this.param.slideWidth = this.param.mobileSlide;
            this.param.range = this.param.mobileRange;
        } else {
            // desktop
            this.param.slideWidth = this.param.desktopSlide;
            this.param.range = this.param.desktopRange;
        }

    }


    bindEvents() {
        this.nodes.ctrl.left.addEventListener('click', () => this.slide(0));
        this.nodes.ctrl.right.addEventListener('click', () => this.slide(1));
        // bind events to bullets
        const bullets = this.nodes.ctrl.bullets.querySelectorAll('.bullet');
        bullets.forEach(b => b.addEventListener('click', () => this.slideTo(b.dataset.slideId)));
        // bind events to slides
        const slides = this.nodes.slider.querySelectorAll('.item');

        slides.forEach(s => s.addEventListener('click', () => {
            const slideId = parseInt(s.dataset.slideId);
            this.slideTo(slideId);
        }))
    }

    slideTo(num) {
        this.state.currentSlide = parseInt(num);
        const halfRange = Math.trunc((this.param.range / 2));
        let leftOffset = (-1) * (this.state.currentSlide - 1 - halfRange) * this.param.slideWidth;
        this.nodes.tape.style.left = `${leftOffset}%`;
        this.updateBullets();
        this.updateSlideRange();
    }

    updateSlideRange() {
        const slides = this.nodes.slider.querySelectorAll('.item');
        const current = parseInt(this.state.currentSlide);
        slides.forEach(s => {
            s.classList.toggle('current',false);
            s.classList.toggle('sub',false);

            const slideId = parseInt(s.dataset.slideId);
            if(current === slideId) {
                s.classList.toggle('current', true);
            } else if(current === slideId+1 || current === slideId-1) {
                s.classList.toggle('sub', true);
            }
        });
    }

    updateBullets() {
        const bullets = this.nodes.ctrl.bullets.querySelectorAll('.bullet');
        bullets.forEach(b => {
            b.classList.toggle('active', false);
            if(parseInt(b.dataset.slideId) === parseInt(this.state.currentSlide)) {
                // mark
                b.classList.toggle('active', true);
            }
        })
    }

    slide(dir) {
        let c;
        if(dir === 0) {
            // slide left
            c = this.state.currentSlide - 1;
            if(c <= 0) c = this.param.totalSlides;
        } else if(dir === 1) {
            // slide right
            c = this.state.currentSlide + 1;
            if(c > this.param.totalSlides) c=1;
        }
        this.slideTo(c);
    }
}
