import { parseUnits, formatEther } from "ethers/lib/utils";

export function formatAddress(address) {
  return address.slice(0, 5) + '...' + address.slice(-4);
}

export function formatToken(amount, decimals) {
  if (typeof amount !== 'string') amount = amount.toString();
  const wei = parseUnits(amount, 'wei');
  if (wei.isZero()) {
    return '0.0';
  }
  const remainder = wei.mod(Number(`1e${18 - decimals}`).toString());
  return formatEther(wei.sub(remainder).toString());
}

export function formatTxLink(txHash) {
  return `${process.env.WEB3_EXPLORER_URL}/tx/${txHash}`
}
