export default class pubSlider {

    ids = {
        slider: null,
        ctrl: {
            left: null,
            right: null,
            bullets: null
        }
    }

    nodes = {
        slider: null,
        tape: null,
        ctrl: {
            left: null,
            right: null,
            bullets: null
        }
    }

    param = {
        totalSlides: 0,
        slideWidth: 0,
        steps: []
    }

    state = {
        currentSlide: 1
    }

    resizeObserver = false;

    constructor(sliderId, leftSlideId, rightSlideId, bulletsId) {
        this.ids.slider = sliderId;
        this.ids.ctrl.left = leftSlideId;
        this.ids.ctrl.right = rightSlideId;
        this.ids.ctrl.bullets = bulletsId;
        this.init();
    }

    init() {
        this.nodes.slider = document.getElementById(this.ids.slider);
        if(!this.nodes.slider) {
            return;
        }
        this.nodes.tape = this.nodes.slider.querySelector('.tape');
        this.nodes.ctrl.left = document.getElementById(this.ids.ctrl.left);
        this.nodes.ctrl.right = document.getElementById(this.ids.ctrl.right);
        this.nodes.ctrl.bullets = document.getElementById(this.ids.ctrl.bullets);

        // get total slides
        this.param.totalSlides = this.nodes.slider.querySelectorAll('.slide').length;

        // calc dimensions
        this.calcDimensions();

        // bind events
        this.bindEvents();
        // run resize observer
        this.watchSize();
    }

    calcDimensions() {
        // get slide width
        const slide = this.nodes.tape.querySelector('.slide');
        this.param.slideWidth = parseFloat(slide.offsetWidth);
        // salculate slide steps
        for(let s=0;s<this.param.totalSlides;s++) {
            this.param.steps[s] = s * this.param.slideWidth;
        }
    }


    bindEvents() {
        this.nodes.ctrl.left.addEventListener('click', () => this.slide(0));
        this.nodes.ctrl.right.addEventListener('click', () => this.slide(1));

        // bind touch event
        this.nodes.tape.addEventListener('touchend', (e) => this.handleTapeTouch(e));
        this.nodes.tape.addEventListener('touchcancel', (e) => this.handleTapeTouch(e));

        // bind events to bullets
        const bullets = this.nodes.ctrl.bullets.querySelectorAll('.bullet');
        bullets.forEach(b => b.addEventListener('click', () => this.slideTo(b.dataset.slideId)));
    }

    handleTapeTouch(e) {
        // detect current slide after scrolling
        this.touchMagnetTimer = setTimeout(() => this.detectSlide(), 500);
    }

    detectSlide() {
        const left = this.nodes.tape.scrollLeft;
        let slide = false;
        for(let s in this.param.steps) {
            const edge = this.param.steps[s] + this.param.slideWidth / 2;
            if(left <= edge) {
                slide = parseInt(s) + 1;
                break;
            }
        }
        this.state.currentSlide = slide;
        this.updateBullets();
    }

    slideTo(num) {
        this.state.currentSlide = parseInt(num);
        let leftOffset = this.param.steps[this.state.currentSlide - 1];
        this.nodes.tape.scrollLeft = leftOffset;
        this.updateBullets();
    }

    updateBullets() {
        const bullets = this.nodes.ctrl.bullets.querySelectorAll('.bullet');
        bullets.forEach(b => {
            b.classList.toggle('active', false);
            if(parseInt(b.dataset.slideId) === parseInt(this.state.currentSlide)) {
                // mark
                b.classList.toggle('active', true);
            }
        })
    }

    handleResizeSlider() {
        console.log('resize...');
        this.calcDimensions(true);
        this.slideTo(this.state.currentSlide);
    }

    watchSize() {
        this.resizeObserver = new ResizeObserver(() => this.handleResizeSlider());
        this.resizeObserver.observe(this.nodes.slider);
    }

    slide(dir) {
        let c;
        if(dir === 0) {
            // slide left
            c = this.state.currentSlide - 1;
            if(c <= 0) c = this.param.totalSlides;
        } else if(dir === 1) {
            // slide right
            c = this.state.currentSlide + 1;
            if(c > this.param.totalSlides) c=1;
        }
        this.slideTo(c);
    }
}
