const togglePartners = (toggleButton) => {
    const partnersBox = document.querySelector('.partnersBox');
    const buttonText = toggleButton.querySelector('.txt');
    const ext = document.getElementById('extendedPartners');
    if(ext.classList.contains('show')) {
        // hide
        partnersBox.classList.toggle('collapsed', true);
        ext.classList.toggle('show', false);
        buttonText.innerHTML = 'See all partners';
    } else {
        // show
        partnersBox.classList.toggle('collapsed', false);
        ext.classList.toggle('show', true);
        buttonText.innerHTML = 'See less partners';
    }
    toggleButton.classList.toggle('active');
}

export default togglePartners;
