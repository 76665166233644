import scrollTape from './scrollTape';
import togglePartners from './togglePartners';
import membersInformer from './memebersInformer';
import showMovieModal from './showMovie';
import heroSlider from './heroSlider.js';
import heroCardSlider from './heroCardSlider.js';
import showErrowModal from './showErrowModal';
import stakingModal from './stakingModal';
import pubSlider from './pubSlider';
import heroPage from './heroPage';
import web3 from './web3';

document.addEventListener('DOMContentLoaded', async () => {
    if(document.getElementById('splide')) {

        new Splide('#splide', {
            type: 'loop',
            rewind: false,
            perPage: 1,
            perMove: 1,
        }).mount();

        const splide = new Splide('#main-slider', {
            pagination: false,
            cover: true,
            autoplay: true,
            interval: 8000,
        }).mount();

        const thumbnails = document.getElementsByClassName("thumbnail");
        let current = document.getElementsByClassName("thumbnail")[0];

        for (var i = 0; i < thumbnails.length; i++) {
            initThumbnail(thumbnails[i], i);
        }

        function initThumbnail(thumbnail, index) {
            thumbnail.addEventListener("click", function () {
                splide.go(index);
            });
        }

        splide.on("mounted move", function () {
            const thumbnail = thumbnails[splide.index];

            if (thumbnail) {
                if (current) {
                    current.classList.remove("is-active");
                }
                thumbnail.classList.add("is-active");
                current = thumbnail;
            }
        });
    }
    // hero page events
    new heroPage();

    // advisors scroll tape
    new scrollTape('advisersTapeBox', 'advisorsScrollLeft', 'advisorsScrollRight');

    // advisors scroll tape
    new scrollTape('membersList', 'membersScrollLeft', 'membersScrollRight');

    // influencers scroll tape
    const startSlide = window.innerWidth > 990 ? 0:2
    new scrollTape('roadmapTapeBox', 'rmScrollLeft', 'rmScrollRight', startSlide);

    // team members modal
    new membersInformer('membersList');

    // show movie modal
    new showMovieModal();

    new heroSlider('heroSlider', {
        leftSlideId: 'heroScrollLeft',
        rightSlideId: 'heroScrollRight',
        bulletsId: 'heroBullets',
        startingSlide: 3
    });

    // publications slider
    new pubSlider('publicationsSlider', 'pubLeft', 'pubRight', 'pubBullets');
    
    // partners collapser
    const toggleButton = document.getElementById('showAllPartners');
    if(toggleButton) {
        toggleButton.addEventListener('click', () => togglePartners(toggleButton));
    }

    // menu toggle
    const menuToggleButton = document.querySelector('.menuToggler');
    const menu = document.querySelector('.menuWrapper');

    menuToggleButton.addEventListener('click', () => {
        menu.classList.toggle('show', true);
        document.body.style.overflow = 'hidden';
        document.body.style.height = '100vh';
        document.body.style.touchAction = 'none';
    });
    document.addEventListener('click', (e) => {
        const isMenu = menu.contains(e.target);
        const isToggle = menuToggleButton.contains(e.target);
        if((!isMenu || e.target.href) && !isToggle && e.target.id !== 'comingSoon') {
            // close menu
            menu.classList.toggle('show', false);
            document.body.style.overflow = 'auto';
            document.body.style.touchAction = 'auto';
        }
    })

    const menuCloseButton = document.querySelector('.menuWrapper .close');
    menuCloseButton.addEventListener('click', () => {
        const menu = document.querySelector('.menuWrapper');
        menu.classList.toggle('show', false);
        document.body.style.overflow = 'auto';
        document.body.style.touchAction = 'auto';
    });
    document.querySelector('.pride').addEventListener('click', () => {
        document.querySelector('.pride').classList.toggle('prideActive')
    });


    // show modal error
    new showErrowModal();
    
    new stakingModal();
    
    
    // connect to web3
    const connectButton = document.querySelector('#connect');
    document.getElementById('exit').addEventListener('click', () => {
        web3.disconnect()
        connectButton.innerHTML = 'Connect wallet';
        connectButton.disabled = false;
        document.querySelector('.connect').classList.add('connectDissable');
        document.cookie = 'user=; domain=nomadexiles.io;'
        if(document.querySelector('.walletBlock')) {
            document.querySelector('.walletBlock').style.display = 'none';
        }
    });
    const handleAccountChange = async address => {
        document.cookie = `user=${address}; domain=nomadexiles.io;`
        connectButton.innerHTML = web3.helpers.formatAddress(address);
        const [ stakingInfo, walletInfo ] = await Promise.all([
            web3.staking.getStakerInfo(address),
            web3.wallet.fetchAccountInfo(address)
        ]);
        document.querySelector('.connect').classList.remove('connectDissable');
        document.querySelector('#infoWallet').setAttribute("href", `https://bscscan.com/address/${address}`)
        // TODO: do whatever you want with this info
        const { initial, vested, withdrawn } = walletInfo;

        if(document.querySelector('.walletBlock')) {
            document.querySelector('.walletBlock').style.display = 'flex';
            document.getElementById('wallet').innerHTML = `<a href="https://bscscan.com/address/${address}"> ${address}</a>`;
            document.getElementById('initialAmount').innerHTML = web3.helpers.formatToken(initial, 5);
            document.getElementById('withdrawn').innerHTML = web3.helpers.formatToken(withdrawn, 5);
            document.getElementById('availableWithdrawal').innerHTML = web3.helpers.formatToken(vested, 5);

            if(Number(vested)) {
                document.querySelector('#withdrawAll').addEventListener('click', () => {
                    web3.wallet.withdraw();
                });
            } else {
                /* document.querySelector('#withdrawAll').style.display = 'none'; */
            }
        }
        if(document.querySelector('.stakingTable') && stakingInfo?.length) {
            document.querySelector('.stakingTable').style.display = 'flex';
            const options = {
                start: '',
                finish: '',
                type: '',
                amount: '',
                status: '',
                btn: '',
            }
            const stakeTypeText = ['in 1 month', 'in 3 month', 'in 6 month'];
            const stakeTypeValue = [1, 3, 6];
            stakingInfo.forEach((stake, index) => {
                const finish = new Date(stake.start*1000);
                finish.setMonth(finish.getMonth() + stakeTypeValue[stake.stakeType]);
                options.start = options.start + `<span>${new Date(stake.start*1000).toLocaleDateString("en-US")} </span>`;
                options.finish = options.finish + `<span>${finish.toLocaleDateString("en-US")} </span>`;
                options.type = options.type + `<span>${stakeTypeText[stake.stakeType]} </span>`;
                options.amount = options.amount + `<span>${ web3.helpers.formatToken(stake.amount, 5)} </span>`;
                options.status = options.status + `<span>${ stake.closed ? 'Closed': 'Open' } </span>`;
                options.btn = options.btn + ((finish > new Date() || stake.closed) ? '<span></span>' : `<span><button class="btn normal yellow" id="withdraw" value="${index}">Withdraw</button></span>`);
            });
            document.querySelector('.stakingTable').innerHTML = `
            <div>
                <span>Start</span>
                ${options.start}
            </div>
            <div>
                <span>Finish</span>
                ${options.finish}
            </div>
            <div>
                <span>Stake Type</span>
                ${options.type}
            </div>
            <div>
                <span>Initial Amount</span>
                ${options.amount}
            </div>
            <div>
                <span>Status</span>
                ${options.status}
            </div>
            <div class="stakingTableBtn" >
                <span></span>
                ${options.btn}
            </div>
            `
        }
        
        document.querySelectorAll('#withdraw').forEach((btn) => {
            btn.addEventListener('click', () => {
                web3.staking.withdraw(Number(btn.value));
            });
        })
        
    }
    const handleDisconnect = error => {
        connectButton.innerHTML = 'Connect wallet';
        document.cookie = 'user=; domain=nomadexiles.io;'
        /* connectButton.disabled = false; */
    }
    const handleChainChange = (chainId, isExpectedChain) => {
        if (!isExpectedChain) window.location.reload();
    }
    const handleConnect = async () => {
        const address = await web3.connect();
        /* connectButton.disabled = true; */
        document.cookie = `user=${address}; domain=nomadexiles.io;`
        web3.handleChainChange(handleChainChange);
        web3.handleAccountChange(handleAccountChange);
        web3.handleDisconnect(handleDisconnect);
        await handleAccountChange(address);
    }
    connectButton.addEventListener('click', async event => {
        if(connectButton.innerHTML === 'Connect wallet') {
            menu.classList.toggle('show', false);
            document.body.style.overflow = 'auto';
            document.body.style.height = 'auto';
            document.body.style.touchAction = 'auto';
            event.preventDefault();
            await handleConnect();         
        } else {
            document.querySelector('.connect').classList.toggle('connectInfoActive')
        }
    })
    if (web3.hasCachedProvider()) await handleConnect();

});
