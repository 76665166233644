export default class showMovieModal {

    nodes = {
        modal: null,
        backdrop: null,
        modalClose: null,
        modalContent: null
    }

    constructor() {
        this.init();
    }

    init() {
        this.proxyCloseModal = this.handleCloseModal.bind(this);

        this.nodes.backdrop = document.querySelector('.backdrop');
        this.nodes.modal = document.querySelector('.modal');
        this.nodes.modalClose = document.querySelector('.modal .closeModal');
        this.nodes.modalContent = document.querySelector('.modal .content');

        // bind show modal click events by all elements with class showMovieModal
        let triggers = document.querySelectorAll('.showMovieModal');

        triggers.forEach(el => el.addEventListener('click', () => this.handleShowModal(el)))
    }

    renderMovieContent(btn) {
        this.nodes.modalContent.innerHTML = '';
        // render movie
        const iframe = document.createElement('iframe');
        iframe.setAttribute('width', '100%');
        iframe.style.setProperty('aspect-ratio','2 / 1');
        iframe.setAttribute('src', 'https://www.youtube.com/embed/'+ btn.dataset.movielink);
        this.nodes.modalContent.appendChild(iframe);
    }

    handleShowModal(btn) {
        this.nodes.modal.classList.toggle('show',true);
        this.nodes.backdrop.classList.toggle('show',true);
        // bind close event
        this.nodes.modalClose.addEventListener('click', this.proxyCloseModal);
        this.nodes.backdrop.addEventListener('click', this.proxyCloseModal);
        this.renderMovieContent(btn);
    }

    handleCloseModal() {
        this.nodes.modal.classList.toggle('show',false);
        this.nodes.backdrop.classList.toggle('show',false);
        // unbind close event
        this.nodes.modalClose.removeEventListener('click', this.proxyCloseModal);
        this.nodes.backdrop.removeEventListener('click', this.proxyCloseModal);
    }

}
