import web3 from './web3';
import {STAKING_ADDRESS} from "./web3/staking";
import {parseEther} from "ethers/lib/utils";

const STEP = {
  ONE: 'ONE',
  TWO: 'TWO',
  THREE: 'THREE',
  FOUR: 'FOUR'
}

const DURATION = {
  ONE: '1_MONTH',
  THREE: '3_MONTHS',
  SIX: '6_MONTHS'
}

export default class showErrowModal {
  nodes = {
    modal: null,
    backdrop: null,
    modalClose: null,
    modalContent: null
  };

  state = {
    step: STEP.ONE,
    error: null,
    isLoading: false,
    duration: DURATION.ONE,
    amount: 1,              // eth
    allowance: null,        // wei
    apporved: null,
    txHash: null,
  };

  constructor() {
    this.proxyCloseModal = this.handleCloseModal.bind(this);
    this.nodes.backdrop = document.querySelector(".backdrop");
    this.nodes.modal = document.querySelector(".modal");
    this.nodes.modalClose = document.querySelector(".modal .closeModal");
    this.nodes.modalContent = document.querySelector(".modal .content");

    // bind show modal click events by all elements with class showErrowModal
    const trigger = document.getElementById("stakingModal");
    if (!trigger) {
      return;
    }
    trigger.addEventListener("click", () => this.handleShowModal());
  }

  updateState(state) {
    this.state = {...this.state, ...state};
  }

  updateStateAndRender(state) {
    this.updateState(state);
    this.render();
  }

  handleShowModal() {
    this.nodes.modal.classList.toggle("show", true);
    this.nodes.backdrop.classList.toggle("show", true);
    this.nodes.modal.classList.add("errorModal");
    this.nodes.modalContent.classList.add("errorContent");
    // bind close event
    this.nodes.modalClose.addEventListener("click", this.proxyCloseModal);
    this.nodes.backdrop.addEventListener("click", this.proxyCloseModal);
    // reset state
    this.updateStateAndRender({ step: STEP.ONE, error: null });
  }

  handleCloseModal() {
    this.nodes.modal.classList.toggle("show", false);
    this.nodes.backdrop.classList.toggle("show", false);
    // unbind close event
    this.nodes.modalClose.removeEventListener("click", this.proxyCloseModal);
    this.nodes.backdrop.removeEventListener("click", this.proxyCloseModal);
  }

  render() {
    let $markup;
    if(document.querySelector('#connect').innerHTML === 'Connect wallet') {
      this.renderErrorConnect()
      return;
    }
    switch (this.state.step) {
      case STEP.TWO: $markup = this.renderStepTwo(this.state); break;
      case STEP.THREE: $markup = this.renderStepThree(this.state); break;
      default: $markup = this.renderStepOne(this.state);
    }
    if (!$markup) return;
    this.nodes.modalContent.innerHTML = ''
    this.nodes.modalContent.appendChild($markup);
  }

  async fetchAllowance() {
    const [account] = await web3.getAccounts();
    return web3.token.fetchAllowance(account, STAKING_ADDRESS);
  }

  // ---------------------------------------------------------------------------
  // errorConnect
  // ---------------------------------------------------------------------------
  renderErrorConnect() {
    const $root = document.createElement('div');
    $root.innerHTML = `
      <span>In order to state PRIDE tokens, you must connect a wallet.</span>
      <div class="errorBtnGroup">
        <button class="btn normal outlined" id="cancel">Cancel</button>
        <button class="btn normal yellow" id="connectModal">Connect</button>
      </div>
    `;
    $root.querySelector('#cancel').addEventListener('click', () => this.handleCloseModal())
    $root.querySelector('#connectModal').addEventListener('click', () => {
      document.querySelector('#connect').click()
      this.handleCloseModal()
    })
    this.nodes.modalContent.innerHTML = ''
    this.nodes.modalContent.appendChild($root);
  }
  // ---------------------------------------------------------------------------
  // step ONE
  // ---------------------------------------------------------------------------

  renderStepOne({ amount, duration, error }) {
    const $root = document.createElement('div');
    $root.innerHTML = `
      <div class="stakingModalApr">
        <strong id="aprValue">${duration === DURATION.SIX ? '36% APR' : duration === DURATION.THREE ? '30% APR' : '24% APR'}</strong>
      </div>
      <div class="stakingBtnGroup">
        <button class="btn normal outlined" id="one"> in 1 month</button>
        <button class="btn normal outlined" id="three"> in 3 month</button>
        <button class="btn normal outlined" id="six"> in 6 month</button>
      </div>
      <div class="stakingModalStake">
        <label for="stakeInput">How much PRIDE would you like to stake?</label><br>
        <input type="number" value="${amount}" id="stakeInput"/>
        <div class="error textCenter ${error ? 'errorActive' : ''}" id="error">${error}</div>
      </div>
      <div class="errorBtnGroup">
        <button class="btn normal outlined" id="cancel">Cancel</button>
        <button class="btn normal yellow" id="submitStaking">Submit</button>
      </div>
    `;

    const $one = $root.querySelector("#one");
    const $three = $root.querySelector("#three");
    const $six = $root.querySelector("#six");
    const $input = $root.querySelector('#stakeInput');

    {
      const $active = duration === DURATION.THREE ? $three : duration === DURATION.SIX ? $six : $one;
      $active.classList.add('yellow');
    }

    const isValid = (value) => {
      return Number(value) > 0;
    }

    $one.addEventListener("click", () => this.updateStateAndRender({ duration: DURATION.ONE }));
    $three.addEventListener("click", () => this.updateStateAndRender({ duration: DURATION.THREE }));
    $six.addEventListener("click", () => this.updateStateAndRender({ duration: DURATION.SIX }));
    $root.querySelector('#cancel').addEventListener('click', () => this.handleCloseModal());
    $root.querySelector('#submitStaking').addEventListener('click', () => {
      const amount = $input.value;
      if (isValid(amount)) {
        this.updateStateAndRender({ amount, error: null, step: STEP.TWO });
      } else {
        this.updateStateAndRender({ error: 'Stake must be greater than 0' });
      }
    })

    return $root;
  }

  // ---------------------------------------------------------------------------
  // step TWO
  // ---------------------------------------------------------------------------

  renderStepTwo(state) {
    const { allowance, amount, txHash } = state;
    if (txHash) return this.renderAwaitingAllowance(state);
    if (!allowance || parseEther(amount).gt(allowance)) return this.renderIncreaseAllowance(state);
    return this.updateStateAndRender({ step: STEP.THREE });
  }

  renderIncreaseAllowance({ allowance, amount, error }) {
    const $root = document.createElement('div');
    const diff = allowance ? web3.helpers.parseEther(amount).sub(allowance).toString() : null;

    $root.innerHTML = `
      <p>
        You've allowed the staking contract to transfer ${web3.helpers.formatToken(allowance ? allowance : '0', 4)} PRIDE tokens from Your account.
        Please, increase PRIDE allowance for the staking contract by ${web3.helpers.formatToken(diff ? diff : '0', 4)} PRIDE.
      </p>
      <div class="error textCenter ${error ? 'errorActive' : ''}" id="error">${error}</div>
      <div class="errorBtnGroup">
        <button class="btn normal outlined" id="cancel">Cancel</button>
        <button class="btn normal yellow" id="submit" ${!diff ? 'disabled' : ''}>Increase allowance</button>
      </div>
    `;

    $root.querySelector('#cancel').addEventListener('click', () => this.handleCloseModal())
    $root.querySelector('#submit').addEventListener('click',  async () => {
      try {
        const { hash: txHash } = await web3.token.approve(STAKING_ADDRESS, web3.helpers.parseEther(amount));
        this.updateStateAndRender({ error: null, txHash });
        web3.waitForTransaction(txHash).then(this.fetchAllowance).then(allowance => this.updateStateAndRender({ allowance, txHash: null }));
      } catch (e) {
        const error = e.reason && e.reason || e.data && e.data.message || e.message;
        this.updateStateAndRender({ error });
      }
    });

    if (!allowance) this.fetchAllowance().then(allowance => this.updateStateAndRender({ allowance }));

    return $root;
  }

  renderAwaitingAllowance({ txHash }) {
    const $root = document.createElement('div');
    $root.innerHTML = `
      <p>Waiting until <a href="${web3.helpers.formatTxLink(txHash)}" target="_blank">Your transaction</a> is mined. Please, wait.</p>
      <div class="errorBtnGroup">
        <button class="btn normal outlined" id="cancel">Cancel</button>
      </div>
    `;
    $root.querySelector('#cancel').addEventListener('click', () => this.handleCloseModal())
    return $root;
  }

  // ---------------------------------------------------------------------------
  // step THREE
  // ---------------------------------------------------------------------------

  renderStepThree(state) {
    const { isLoading, txHash } = state;
    if (txHash && isLoading) return this.renderAwaitingStaking(state);
    if (txHash && !isLoading) return this.renderSuccess(state);
    return this.renderStake(state);
  }

  renderStake({ amount, duration, isLoading, error }) {
    const $root = document.createElement('div');
    amount = web3.helpers.parseEther(amount);
    $root.innerHTML = `
      <p class="textCenter">Stake ${web3.helpers.formatToken(amount, 4)} PRIDE</p>
      <div class="error textCenter ${error ? 'errorActive' : ''}" id="error">${error}</div>
      <div class="errorBtnGroup">
        <button class="btn normal outlined" id="cancel">Cancel</button>
        <button class="btn normal yellow" id="submit" ${isLoading ? 'disabled' : ''}>Submit</button>
      </div>
    `;
    $root.querySelector('#cancel').addEventListener('click', () => this.handleCloseModal());
    $root.querySelector('#submit').addEventListener('click', async () => {
      try {
        this.updateStateAndRender({ isLoading: true });
        const index = duration === DURATION.ONE ? 0 : duration === DURATION.THREE ? 1 : 2;
        const { hash: txHash } = await web3.staking.deposit(index, amount);
        this.updateStateAndRender({ error: null, txHash });
        web3.waitForTransaction(txHash).then(this.fetchAllowance).then(allowance => this.updateStateAndRender({ allowance, isLoading: false }));
      } catch (e) {
        const error = e.reason && e.reason || e.data && e.data.message || e.message;
        this.updateStateAndRender({ error, isLoading: false });
      }
    });
    return $root;
  }

  renderAwaitingStaking({ txHash }) {
    const $root = document.createElement('div');
    $root.innerHTML = `
      <p>Waiting until <a href="${web3.helpers.formatTxLink(txHash)}" target="_blank">Your transaction</a> is mined. Please, wait.</p>
      <div class="errorBtnGroup">
        <button class="btn normal outlined" id="cancel">Cancel</button>
      </div>
    `;
    $root.querySelector('#cancel').addEventListener('click', () => this.handleCloseModal())
    return $root;
  }

  renderSuccess({ amount }) {
    const $root = document.createElement('div');
    amount = web3.helpers.parseEther(amount);
    $root.innerHTML = `
      <p class="textCenter">Congratulations! You've successfully staked ${web3.helpers.formatToken(amount, 4)} PRIDE</p>
      <div class="errorBtnGroup">
        <button class="btn normal outlined" id="cancel">Ok</button>
      </div>
    `;
    $root.querySelector('#cancel').addEventListener('click', () => {
      this.updateState({ txHash: null });
      this.handleCloseModal()
    });
    return $root;
  }

}
