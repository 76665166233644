import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import  { providers } from 'web3modal';
import { normalizeChainId } from "./util";
import Web3 from "web3";
import {ethers} from "ethers";

const WALLETCONNECT_PARAMS = {
  rpc: { [process.env.WEB3_CHAIN_ID]: process.env.WEB3_RPC_URL },
  chainId: process.env.WEB3_CHAIN_ID,
  network: 'binance',
  qrcodeModalOptions: { desktopLinks: [] }
}

const ETHEREUM_CHAIN_PARAMS = {
  blockExplorerUrls: [process.env.WEB3_EXPLORER_URL],
  chainId: `0x${Number(process.env.WEB3_CHAIN_ID).toString(16)}`,
  chainName: process.env.WEB3_CHAIN_NAME,
  nativeCurrency: {
    name: 'Binance',
    symbol: 'BNB',
    decimals: 18
  },
  rpcUrls: [process.env.WEB3_RPC_URL],
}

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: WALLETCONNECT_PARAMS
  }
};

if (!window.ethereum) {
  providerOptions['custom-metamask'] = {
    display: {
      logo: providers.METAMASK.logo,
      name: 'Install MetaMask',
      description: 'Connect using browser wallet'
    },
    package: {},
    connector: async () => {
      window.open('https://metamask.io')
      throw new Error('MetaMask not installed');
    }
  }
}

let provider;

const web3Modal = new Web3Modal({ cacheProvider: true, providerOptions });

const isExpectedChain = chainId => {
  return normalizeChainId(chainId) === ETHEREUM_CHAIN_PARAMS.chainId;
}

const hasDisconnectCallback = () => {
  const web3 = new Web3(provider);
  return !web3.currentProvider.isMetaMask;
}

const connect = async () => {
  provider = await web3Modal.connect();
  const chainId = await provider.request({ method: 'eth_chainId' })
  if (!isExpectedChain(chainId)) {
    try {
      await provider.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: ETHEREUM_CHAIN_PARAMS.chainId }] });
    } catch (error) {
      if (error.code && error.code === 4902) await provider.request({ method: 'wallet_addEthereumChain', params: [ ETHEREUM_CHAIN_PARAMS ] });
      else throw error;
    }
  }
}

const disconnect = async () => {
  if (provider.close) await provider.close();
  await web3Modal.clearCachedProvider();
  provider = null;
}

const getAccounts = async () => {
  const web3 = new Web3(provider);
  return web3.eth.getAccounts();
};

const getProvider = () => {
  return provider;
}

const handleAccountsChange = callback => {
  provider.on('accountsChanged', accounts => callback(accounts));
};

const handleChainChange = callback => {
  provider.on('chainChanged', chainId => callback(chainId));
};

const handleConnect = callback => {
  provider.on("connect", info => callback(info));
}

const handleDisconnect = callback => {
  provider.on("disconnect", async error => callback(error));
}

const hasCachedProvider = () => !!web3Modal.cachedProvider;

const waitForTransaction = async txHash => {
  const ethersProvider = new ethers.providers.Web3Provider(provider);
  return ethersProvider.waitForTransaction(txHash);
}

export default {
  connect,
  disconnect,
  getAccounts,
  getProvider,
  handleAccountsChange,
  handleChainChange,
  handleConnect,
  handleDisconnect,
  hasCachedProvider,
  hasDisconnectCallback,
  isExpectedChain,
  waitForTransaction
};
