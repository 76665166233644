export default class showErrowModal {

    nodes = {
        modal: null,
        backdrop: null,
        modalClose: null,
        modalContent: null,

        connect: false,
    }

    constructor() {
        this.init();
    }

    init() {
        this.proxyCloseModal = this.handleCloseModal.bind(this);

        this.nodes.backdrop = document.querySelector('.backdrop');
        this.nodes.modal = document.querySelector('.modal');
        this.nodes.modalClose = document.querySelector('.modal .closeModal');
        this.nodes.modalContent = document.querySelector('.modal .content');
        /* if(document.location.href.includes('staking') && document.querySelector('#connect').innerHTML === 'Connect wallet') {
            this.redirectModal()
        } */
        // bind show modal click events by all elements with class showErrowModal
        const trigger = document.getElementById('playDemo');
        if(!trigger) {
            return;
        }
        trigger.addEventListener('click', () => this.handleShowModalAlfa())
        
        /* const connectButton = document.querySelector('#connect');
        connectButton.addEventListener('click', () => this.handleShowModal()) */
    }
    redirectModal() {
        this.nodes.modal.classList.toggle('show',true);
        this.nodes.backdrop.classList.toggle('show',true);
            // bind close event
        this.renderError();
        this.nodes.modalContent.innerHTML = `
        <div style="margin-bottom: 15px">
            <span>Metamask does not currently support your browser well.<span>
        </div>`
    }
    renderError() {
        this.nodes.backdrop.classList.add('backdropError');
        this.nodes.modal.classList.add('errorModal');
        this.nodes.modalContent.classList.add('errorContent');
    }

    handleShowModal() {
        const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        const isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase())
        if(document.querySelector('#connect').innerHTML === 'Connect wallet' && (!isChrome || isMobile)) {
            this.nodes.modal.classList.toggle('show',true);
            this.nodes.backdrop.classList.toggle('show',true);
            // bind close event
            this.nodes.modalClose.addEventListener('click', this.proxyCloseModal);
            this.nodes.backdrop.addEventListener('click', this.proxyCloseModal);
            this.renderError();

            this.nodes.modalContent.innerHTML = `
            <div style="margin-bottom: 15px">
                <span>Metamask does not currently support your browser well.<span><br>
                <span>For correct work use Google Chrome.</span>
            </div>
            `
            this.nodes.modalContent.innerHTML = `
            <span>Metamask does not currently support on mobile devices well.</span><br>
            <span>Use the desktop device metamask for correct operation.</span>`
        }

    }
    handleShowModalAlfa() {
        if(this.checkError() || document.querySelector('#connect').innerHTML !== 'Connect wallet') {
            window.location.href = "https://game.nomadexiles.io/";
        } else {
            this.nodes.modal.classList.toggle('show',true);
            this.nodes.backdrop.classList.toggle('show',true);
            // bind close event
            this.nodes.modalClose.addEventListener('click', this.proxyCloseModal);
            this.nodes.backdrop.addEventListener('click', this.proxyCloseModal);
            this.renderError();
        }
    }

    handleCloseModal() {
        this.nodes.modal.classList.toggle('show',false);
        this.nodes.backdrop.classList.toggle('show',false);
        this.nodes.modalContent.classList.toggle('errorContent',false);
        this.nodes.modal.classList.toggle('errorModal',false);
        this.nodes.backdrop.classList.toggle('backdropError',false);

        // unbind close event
        this.nodes.modalClose.removeEventListener('click', this.proxyCloseModal);
        this.nodes.backdrop.removeEventListener('click', this.proxyCloseModal);
    }

    checkError() {
        const isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase())
        switch (true) {
            case isMobile:
                this.errorMobile()
                return false;
            case document.querySelector('#connect').innerHTML === 'Connect wallet': // Проверка на подключение к кошельку
                this.nodes.modalContent.innerHTML = `
                    <span>To access the Nomad Exiles, you need to connect a wallet.</span>
                    <div class="errorBtnGroup">
                        <button class="btn normal outlined" id="cancel">Cancel</button>
                        <button class="btn normal yellow" id="connectModal">Connect</button>
                    </div>`
                document.querySelector('#cancel').addEventListener('click', () => this.handleCloseModal())
                document.querySelector('#connectModal').addEventListener('click', () => {
                    document.querySelector('#connect').click()
                    this.handleCloseModal()
                })
                return false;
            default:
                return true;
        }
    }
    errorMobile() {
        this.nodes.modalContent.innerHTML = `
            <span>To access the Nomad Exiles, you need to connect a wallet.</span><br><br>
            <span>Metamask is not currently supported on mobile devices. Use desktop device metamask.</span>
        `
    }
}
