import {getContract, getContractWithSigner} from "./util";
import web3 from "./web3";

const TOKEN_ABI = [
  'function totalSupply() external view returns (uint256)',
  'function balanceOf(address account) external view returns (uint256)',
  'function transfer(address recipient, uint256 amount) external returns (bool)',
  'function allowance(address owner, address spender) external view returns (uint256)',
  'function approve(address spender, uint256 amount) external returns (bool)'
];

const TOKEN_ADDRESS = process.env.WEB3_TOKEN_ADDRESS;

export default {
  async approve(spender, amount) {
    const contractWithSigner = getContractWithSigner(web3.getProvider(), TOKEN_ADDRESS, TOKEN_ABI);
    return contractWithSigner.approve(spender, amount);
  },
  async fetchAllowance(owner, spender) {
    const contract = getContract(web3.getProvider(), TOKEN_ADDRESS, TOKEN_ABI);
    const allowance = await contract.allowance(owner, spender);
    return allowance.toString();
  },
  async fetchBalance(account) {
    const contract = await getContract(web3.getProvider(), TOKEN_ADDRESS, TOKEN_ABI);
    const balance = await contract.balanceOf(account);
    return balance.toString();
  }
};
