import { ethers } from "ethers";

export function normalizeChainId(chainId) {
  return (typeof chainId === 'number') ? `0x${chainId.toString(16)}` : chainId;
}

export function getContract(web3provider, address, abi) {
  const provider = new ethers.providers.Web3Provider(web3provider);
  return new ethers.Contract(address, abi, provider);
}

export function getContractWithSigner(web3provider, address, abi) {
  const provider = new ethers.providers.Web3Provider(web3provider);
  const contract = new ethers.Contract(address, abi, provider);
  const signer = provider.getSigner();
  return contract.connect(signer);
}

export default {
  getContract,
  getContractWithSigner,
  normalizeChainId,
}
