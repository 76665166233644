export default class memebersInformer {

    ids = {
        membersList: null
    }

    nodes = {
        membersList: null,
        modal: null,
        backdrop: null,
        modalContent: null,
        modalClose: null
    }

    proxyCloseModal = null;

    constructor(membersListId) {
        this.ids.membersList = membersListId;
        this.init();
    }

    init() {
        this.nodes.membersList = document.getElementById(this.ids.membersList);
        if(!this.nodes.membersList) {
            return;
        }

        this.proxyCloseModal = this.handleCloseModal.bind(this);

        this.nodes.backdrop = document.querySelector('.backdrop');
        this.nodes.modal = document.querySelector('.modal');
        this.nodes.modalClose = document.querySelector('.modal .closeModal');
        this.nodes.modalContent = document.querySelector('.modal .content');

        let members = this.nodes.membersList.querySelectorAll('.member');
        members.forEach((m) => {
            // bind events
            const moreInfo = m.querySelector('.more .inlineBtn');
            const name = m.querySelector('.name');
            const photo = m.querySelector('.person');
            if(photo) {
                photo.addEventListener('click', () => this.handleShowModal(m));
            }
            if(moreInfo) {
                moreInfo.addEventListener('click', () => this.handleShowModal(m));
            }
            if(name) {
                name.addEventListener('click', () => this.handleShowModal(m))
            }
        })
    }

    renderMemberText(member) {

        // reset content
        this.nodes.modalContent.innerHTML = '';
        // render new
        const table = document.createElement('div');
        table.setAttribute('class', 'memberInfo');

        const cell1 = document.createElement('div');
        cell1.setAttribute('class', 'img');

        const photo = document.createElement('img');
        photo.setAttribute('class', 'photo');
        photo.setAttribute('src', member.querySelector('.person > img').getAttribute('src'));
        cell1.appendChild(photo);

        const position = document.createElement('div');
        position.setAttribute('class', 'position');
        position.innerText = member.querySelector('.position').innerText;

        cell1.appendChild(position);

        const cell2 = document.createElement('div');
        cell2.setAttribute('class', 'text');

        const header = document.createElement('h3');
        header.innerText = member.querySelector('.name').innerText;

        cell2.appendChild(header);

        if(member.querySelector('.perks')) {
            const perks = document.createElement('div');
            perks.setAttribute('class', 'perks');
            perks.innerText = member.querySelector('.perks').innerText;
            cell2.appendChild(perks);
        }

        if(member.hasAttribute('data-linkedin')) {
            const linkedin = document.createElement('a');
            linkedin.setAttribute('targer', '_blank');
            linkedin.innerHTML = 'Linkedin<svg class="i"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/img/ui.svg#i-outer-link"></use></svg>';
            linkedin.setAttribute('class', 'btn large yellow');
            linkedin.setAttribute('href', member.dataset.linkedin);
            cell2.appendChild(linkedin);
        }

        table.appendChild(cell1);
        table.appendChild(cell2);

        this.nodes.modalContent.appendChild(table);
    }

    handleCloseModal() {
        document.body.style.overflow = 'auto';
        document.body.style.touchAction = 'auto';
        this.nodes.modal.classList.toggle('show',false);
        this.nodes.backdrop.classList.toggle('show',false);
        // unbind close event
        this.nodes.modalClose.removeEventListener('click', this.proxyCloseModal);
        this.nodes.backdrop.removeEventListener('click', this.proxyCloseModal);
    }

    handleShowModal(member) {
        this.nodes.modal.classList.toggle('show',true);
        this.nodes.backdrop.classList.toggle('show',true);
        // bind close event
        this.nodes.modalClose.addEventListener('click', this.proxyCloseModal);
        this.nodes.backdrop.addEventListener('click', this.proxyCloseModal);
        this.renderMemberText(member);
        document.body.style.overflow = 'hidden';
        document.body.style.touchAction = 'none';
    }
}
