export default class scrollTape {

    ids = {
        box: false,
        bar: {
            left: false,
            right: false
        }
    }

    nodes = {
        box: null,
        tape: null,
        bar: {
            left: null,
            right: null
        }
    }

    resizeObserver = false;

    param = {
        initialPosition: false,
        countCells: false,
        current: false,
        fullPlaces: 0,
        cell: {
            width: false,
            gap: 0
        }
    }

    constructor(tapeBoxId, leftBtnId, rightBtnId, initialPosition = 0) {
        this.ids.box = tapeBoxId;
        this.ids.bar.left = leftBtnId;
        this.ids.bar.right = rightBtnId;
        this.param.initialPosition = initialPosition;
        this.init();
    }

    init() {
        this.nodes.box = document.getElementById(this.ids.box);
        if(!this.nodes.box) {
            return;
        }
        this.nodes.tape = this.nodes.box.querySelector('.tape');
        if(!this.nodes.tape) {
            console.log('Can\'t find tape for box id = '+this.ids.box);
            return;
        }

        // get buttons
        this.nodes.bar.left = document.getElementById(this.ids.bar.left);
        this.nodes.bar.right = document.getElementById(this.ids.bar.right);

        // count cells
        this.param.countCells = this.nodes.tape.querySelectorAll('.item').length;

        this.calcDimensions();

        // set initial position
        this.param.current = this.param.initialPosition;
        this.slideTo();

        // run resize observer
        this.watchSize();
        // bind events
        this.bindEvents();
    }

    bindEvents() {
        this.nodes.bar.left.addEventListener('click', () => this.slide(0));
        this.nodes.bar.right.addEventListener('click', () => this.slide(1));
    }

    changeNavButtonState() {
        // reset button state
        this.nodes.bar.left.classList.toggle('disabled', false);
        this.nodes.bar.right.classList.toggle('disabled', false);

        if(this.param.current == 0) {
            // disable right button
            this.nodes.bar.right.classList.toggle('disabled', true);
        }

        if(this.param.current + this.param.fullPlaces == this.param.countCells) {
            // disable left button
            this.nodes.bar.left.classList.toggle('disabled', true);
        }
    }

    slide(direction) {

        if(direction === 1) {
            // right
            if(this.param.current > 0) {
                // scroll
                this.param.current--;
            }
        } else if(direction === 0) {
            // left
            if(this.param.current + this.param.fullPlaces < this.param.countCells) {
                // scroll
                this.param.current++;
            }
        }
        this.slideTo();
    }

    slideTo() {
        this.changeNavButtonState();
        // move type
        this.nodes.tape.style.marginLeft = (-1) * this.param.current * (this.param.cell.width + this.param.cell.gap) + 'px';
    }

    calcDimensions() {
        // calculate width of one cell
        const cell = this.nodes.tape.querySelector('.item');
        if(!cell) {
            console.log('Can\'t find scroll item for box id = ', this.ids.box);
            return;
        }
        this.param.cell.width = parseInt(cell.clientWidth);
        // get gaps between items
        const tapeStyles = getComputedStyle(this.nodes.tape);
        this.param.cell.gap = parseInt(tapeStyles.gap);
        if(isNaN(this.param.cell.gap)) this.param.cell.gap = 0;
        // calculate available full places fot cells
        this.param.fullPlaces = Math.trunc(this.nodes.box.clientWidth / (this.param.cell.width + this.param.cell.gap));
    }

    watchSize() {
        this.resizeObserver = new ResizeObserver(() => this.handleResizeType());
        this.resizeObserver.observe(this.nodes.box);
    }

    handleResizeType() {
        this.calcDimensions();
    }

}
