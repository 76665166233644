import web3 from './web3';
import staking from './staking';
import token from './token';
import wallet from './wallet';
import {parseEther} from 'ethers/lib/utils';
import {formatAddress, formatTxLink, formatToken} from "./helpers";

{
  const style = document.createElement('style');
  style.innerHTML = '.web3modal-modal-lightbox { z-index: 10 !important; }';
  document.head.appendChild(style);
}

async function connect() {
  await web3.connect();
  const [ address ] = await web3.getAccounts();
  return address;
}

async function disconnect() {
  return web3.disconnect();
}

function handleChainChange(cb) {
  return web3.handleChainChange(chainId => {
    cb(chainId, web3.isExpectedChain(chainId));
  });
}

function handleAccountChange(cb) {
  return web3.handleAccountsChange(accounts => {
    const [ address ] = accounts;
    cb(address);
  });
}

function handleDisconnect(cb) {
  web3.handleDisconnect(error => {
    cb(error);
  })
}

function hasCachedProvider() {
  return web3.hasCachedProvider();
}

export default {
  connect,
  disconnect,
  handleChainChange,
  handleAccountChange,
  handleDisconnect,
  hasCachedProvider,
  getAccounts: web3.getAccounts,
  waitForTransaction: web3.waitForTransaction,
  helpers: {
    formatAddress,
    formatTxLink: formatTxLink,
    formatToken,
    parseEther
  },
  staking,
  token,
  wallet
}
