import util from "./util";
import web3 from './web3';

const STAKING_ABI = [
  'function stakers(address stakerAddress) public view returns (uint256 extsts, uint256 count, uint256 summerDeposit, uint256 summerAfter)',
  'function countOfStakeTypes() public view returns (uint256)',
  'function deposit(uint8 stakeTypeIndex, uint256 amount) public returns(uint)',
  'function withdraw(uint8 stakeIndex) public',
  'function getStakeTypeFinePeriodAndFine(uint8 stakeTypeIndex, uint periodIndex) public view returns(uint, uint)',
  'function getStakerStakeParams(address stakerAddress, uint stakeIndex) public view returns(bool closed, uint amount, uint amountAfter, uint stakeType, uint start, uint finished)',
  'function stakeTypes(uint256 index) public view returns(active bool, periodInDays uint256, apy uint256, finesPeriodsCount uint256)'
];

export const STAKING_ADDRESS = process.env.WEB3_STAKING_ADDRESS;

const getContract = () => util.getContract(web3.getProvider(), STAKING_ADDRESS, STAKING_ABI);
const getContractWithSigner = () => util.getContractWithSigner(web3.getProvider(), STAKING_ADDRESS, STAKING_ABI);

export default {
  async deposit(stakeProgramIndex, amount) {
    const contractWithSigner = getContractWithSigner();
    return contractWithSigner.deposit(stakeProgramIndex, amount);
  },
  async getCountOfStakeTypes() {
    const contract = getContract();
    return contract.countOfStakeTypes();
  },
  async getStakerInfo(stakerAddress) {
    const stakes = await this.getStakes(stakerAddress);
    const countOfStakes = stakes.count.toNumber();
    return Promise.all([...Array.from({length: countOfStakes}).keys()].map(stakeIndex => this.getStakerStakeParams(stakerAddress, stakeIndex)));
  },
  async getStakingParams() {
    const stakeTypesCount = (await this.getCountOfStakeTypes()).toNumber();
    return Promise.all([...Array.from({length: stakeTypesCount}).keys()].map(index => this.getStakeType(index)));
  },
  async getStakes(stakerAddress) {
    const contract = getContract();
    return contract.stakers(stakerAddress);
  },
  async getStakerStakeParams(stakerAddress, stakeIndex) {
    const contract = getContract();
    const [ closed, amount, amountAfter, stakeType, start, finished ] = await contract.getStakerStakeParams(stakerAddress, stakeIndex);
    return {
      stakeType: stakeType.toNumber(),
      closed,
      amount: amount.toString(),
      amountAfter: amountAfter.toString(),
      start: start.toNumber(),
      finished: finished.toNumber()
    }
  },
  async getStakeType(index) {
    const contract = await getContract();
    return  contract.stakeTypes(index);
  },
  async withdraw(stakeIndex) {
    const contractWithSigner = await getContractWithSigner();
    return contractWithSigner.withdraw(stakeIndex);
  },
  async withdrawAll() {
    const contractWithSigner = await getContractWithSigner();
    return contractWithSigner.withdraw();
  }
};
