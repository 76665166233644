export default class heroPage {

    nodes = {
        page: null,
        tabSet: null
    }

    constructor() {
        window.addEventListener('DOMContentLoaded', () => this.init())
    }

    init() {
        const hero = document.querySelector('.heroPage .hero');
        const tabSet = document.querySelector('.heroPage .tabSet');
        if(!hero || !tabSet) {
            return false;
        }
        this.nodes.hero = hero;
        this.nodes.tabSet = tabSet;

        this.bindEvents();
    }

    bindEvents() {
        const tabs = this.nodes.tabSet.querySelectorAll('.tabs input');
        tabs.forEach(inp => {
           inp.addEventListener('click', () => {
               this.nodes.hero.setAttribute('class', 'hero');
               this.nodes.hero.classList.toggle(inp.value, true);
           })
        });
    }

}
