import util from './util';
import web3 from './web3';

const WALLET_ABI = [
  'function getAccountInfo(address account) external view returns (uint256, uint256, uint256)',
  'function withdraw() external returns (uint256)',
];

export const WALLET_ADDRESS = process.env.WEB3_WALLET_ADDRESS;

const getContract = () => util.getContract(web3.getProvider(), WALLET_ADDRESS, WALLET_ABI);
const getContractWithSigner = () => util.getContractWithSigner(web3.getProvider(), WALLET_ADDRESS, WALLET_ABI);

export default {
  async fetchAccountInfo(accountAddress) {
    const contract = getContract();
    const [ initial, withdrawn, vested ] = (await contract.getAccountInfo(accountAddress)).map(value => value.toString());
    return { initial, withdrawn, vested };
  },
  async withdraw() {
    const contractWithSigner = getContractWithSigner();
    return contractWithSigner.withdraw();
  }
};

